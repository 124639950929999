<template>
    <!-- <card body-classes="p-0"> -->
    <div>
        <!-- CRU Modal -->
        <b-modal
            id="cru_modal"
            :title="`${
                cru_modal.goal.id
                    ? `Update priority ${cru_modal.goal.priority} Goal`
                    : 'Create Goal'
            }`"
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div>
                <b-row>
                    <b-col>
                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form
                                role="form"
                                @submit.prevent="handleSubmit(submit_form)"
                            >
                                <input
                                    type="submit"
                                    ref="cru_modal_form_submit_button"
                                    hidden
                                />

                                <b-row>
                                    <b-col sm="12" md="8" class="pr-1">
                                        <el-tooltip
                                            content="The name of your goal."
                                            placement="right"
                                        >
                                            <base-input
                                                label="Name"
                                                :rules="{
                                                    required: true,
                                                }"
                                                :pill="true"
                                                name="Name"
                                                placeholder="Goal Name"
                                                :disabled="$apollo.loading"
                                                v-model="cru_modal.goal.name"
                                            >
                                            </base-input>
                                        </el-tooltip>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col sm="3" class="pr-1">
                                        <el-tooltip
                                            content="Priority e.g. 1, 2, 3 (Controls the default sorting)"
                                            placement="right"
                                        >
                                            <base-input
                                                label="Priority"
                                                :rules="{
                                                    required: true,
                                                    numeric: true,
                                                    min_value: 1,
                                                }"
                                                :pill="true"
                                                type="number"
                                                name="Place"
                                                placeholder="Goal priority e.g. 1"
                                                :disabled="$apollo.loading"
                                                v-model="
                                                    cru_modal.goal.priority
                                                "
                                            >
                                            </base-input>
                                        </el-tooltip>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <html-editor
                                            :pill="true"
                                            label="Goal detail description"
                                            class="mb-3"
                                            name="Detailed Description"
                                            placeholder="A detail description"
                                            v-model="cru_modal.goal.description"
                                            :disabled="$apollo.loading"
                                        >
                                        </html-editor>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-right">
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('cru_modal')"
                            :disabled="cru_modal.loading"
                            >Close
                        </base-button>
                        <base-button
                            type="success"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="cru_modal_save()"
                            :disabled="cru_modal.loading"
                            :loading="cru_modal.loading"
                            :success="cru_modal.success"
                            >Save
                        </base-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>

        <!-- Delete Modal -->
        <b-modal
            id="d_modal"
            title="Confirm"
            size="sm"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div class="text-center">
                <h1>Are you sure?</h1>
                <p>
                    You are about to delete the goal:
                    {{ d_modal.goal.name }}
                </p>
                <base-button
                    type="danger"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="delete_goal()"
                    :loading="d_modal.loading"
                    :disabled="d_modal.loading"
                    :success="d_modal.success"
                >
                    Delete
                </base-button>
                <base-button
                    type="primary"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="$bvModal.hide('d_modal')"
                    :disabled="d_modal.loading"
                    >Cancel
                </base-button>
            </div>
        </b-modal>
        <b-row>
            <b-col cols="6">
                <h1
                    v-if="has_heading"
                    :class="heading_classes"
                    class="pb-0 mb-0"
                >
                    Goals
                </h1>
            </b-col>
            <b-col cols="6" class="text-right mt-2">
                <el-tooltip
                    content="Create Goal"
                    placement="top"
                    v-if="is_hackathon_manager || is_hackathon_creator"
                >
                    <base-button
                        type="primary"
                        round
                        icon
                        size="sm"
                        @click="cru_modal_show()"
                    >
                        <span class="btn-inner"
                            ><i class="fa fa-plus"></i
                        ></span>
                    </base-button>
                </el-tooltip>
            </b-col>
        </b-row>

        <div
            v-show="goals.length > 0"
            class="goal-item-list"
            id="goal-item-container"
        >
            <b-list-group flush data-toggle="checklist">
                <b-list-group-item
                    class="checklist-entry flex-column align-items-start py-2 px-2 list-group-item goal-list-item"
                    v-for="goal in goals"
                    :key="goal.id"
                >
                    <b-row>
                        <b-col sm="auto">
                            <i class="far fa-check-circle text-primary"></i>
                            &nbsp;
                        </b-col>
                        <b-col>
                            <h4>{{ goal.name }}</h4>
                            <small v-html="goal.description"></small>
                        </b-col>
                        <b-col
                            class="text-right"
                            sm="auto"
                            v-if="is_hackathon_manager || is_hackathon_creator"
                        >
                            <el-tooltip content="Edit" placement="top">
                                <a
                                    href="#!"
                                    @click.prevent="cru_modal_show(goal)"
                                    class="table-action"
                                    data-toggle="tooltip"
                                    data-original-title="Update Goal"
                                >
                                    <i class="fas fa-edit"></i>
                                </a>
                            </el-tooltip>
                            <el-tooltip content="Delete" placement="top">
                                <a
                                    href="#!"
                                    @click.prevent="d_modal_show(goal)"
                                    class="table-action"
                                    data-toggle="tooltip"
                                    data-original-title="Update Goal"
                                >
                                    <i class="fas fa-trash"></i>
                                </a> </el-tooltip
                        ></b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
        </div>
        <b-row class="text-center" v-if="!$apollo.loading && goals.length <= 0">
            <b-col>
                <h4 class="text-muted">No Goals Yet</h4>
            </b-col>
        </b-row>
        <b-row v-if="$apollo.loading" class="text-center">
            <b-col>
                <h1>
                    <i class="fas fa-spinner fa-spin ml-1"></i>
                </h1>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Queries
import { GET_HACKATHON_GOALS } from "@/graphql/queries";
import { GET_HACKATHON_GOALS_UN_AUTH } from "@/graphql/queriesUnAuth";

// Mutations
import {
    CREATE_HACKATHON_GOAL,
    UPDATE_HACKATHON_GOAL,
    DELETE_HACKATHON_GOAL,
} from "@/graphql/mutations";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import HtmlEditor from "@/components/Inputs/HtmlEditor";

function has_element(className) {
    return document.getElementsByClassName(className).length > 0;
}

function init_scrollbar(className) {
    if (has_element(className)) {
        new PerfectScrollbar(`.${className}`, { suppressScrollX: true });
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            init_scrollbar(className);
        }, 100);
    }
}

export default {
    components: {
        HtmlEditor,
    },
    props: {
        hackathon_id: {
            type: String,
            description: "The Id of the hackathon being viewed.",
            default: null,
        },
        hackathon_uid: {
            type: String,
            description: "The Id of the hackathon being viewed.",
            default: null,
        },
        max_height: {
            type: Number,
            description: "Controls the max height of the list",
            default: 450,
        },
        has_heading: {
            type: Boolean,
            description: "Controls if the heading is displayed",
            default: true,
        },
        refresh: {
            type: Boolean,
            description: "If true, it refreshes the underlying dataset. ",
            default: false,
        },
        heading_classes: {
            type: Object,
            description: "Classes of the heading",
            default() {
                return {
                    "display-3": true,
                    "text-primary": true,
                };
            },
        },
        un_auth: {
            type: Boolean,
            description: "Controls if the un_auth api should be used.",
            default: false,
        },
    },
    apollo: {
        get_hackathon_goals: {
            query: GET_HACKATHON_GOALS,
            result(res) {
                let flattened = graph_utils.flattened_response(res.data);
                this.goals = [];

                flattened.allHackathonGoal.forEach((el) => {
                    this.goals.push({
                        id: el.id,
                        name: el.name,
                        priority: el.priority,
                        description: el.description,
                    });
                });

                this.goals = this.sort_by_key(this.goals, "priority");

                this.is_hackathon_creator =
                    res.data.hackathonHackathon.isCreator;
                this.is_hackathon_manager =
                    res.data.hackathonHackathon.isManager;

                this.get_max_height_goal_item_container();
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_goals = data;
            },
            skip: true,
        },
        get_hackathon_goals_un_auth: {
            client: "apolloClientUnAuth",

            query: GET_HACKATHON_GOALS_UN_AUTH,
            result(res) {
                let flattened = graph_utils.flattened_response(res.data);
                this.goals = [];

                flattened.allHackathonGoal.forEach((el) => {
                    this.goals.push({
                        id: el.id,
                        name: el.name,
                        priority: el.priority,
                        description: el.description,
                    });
                });

                this.goals = this.sort_by_key(this.goals, "priority");

            
                this.get_max_height_goal_item_container();
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                    hackathon_uid: this.hackathon_uid,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_goals_un_auth = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_goals: null,
                get_hackathon_goals_un_auth: null,
            },
            goals: [],
            is_hackathon_creator: false,
            is_hackathon_manager: false,

            cru_modal: {
                loading: false,
                success: false,

                goal: {
                    id: null,
                    description: "",
                    name: "",
                    priority: null,
                },
            },
            d_modal: {
                loading: false,
                success: false,
                goal: {
                    id: null,
                    priority: null,
                    name: "",
                },
            },
        };
    },
    methods: {
        //#region Modals

        // cru_modal
        cru_modal_show(goal) {
            if (this.is_hackathon_manager) {
                if (goal) {
                    this.cru_modal.goal = goal;
                } else {
                    this.cru_modal.goal = {
                        id: null,
                        description: "",
                        name: "",
                        priority: null,
                    };
                }
                this.$bvModal.show("cru_modal");
            } else {
                this.cru_modal.goal = {
                    id: null,
                    description: "",
                    name: "",
                    priority: null,
                };
            }
        },
        cru_modal_save() {
            this.cru_modal.loading = true;

            let vars = {
                description: this.cru_modal.goal.description,
                priority: Number(this.cru_modal.goal.priority),
                name: this.cru_modal.goal.name,
            };

            let mutation = CREATE_HACKATHON_GOAL;
            if (this.cru_modal.goal.id) {
                mutation = UPDATE_HACKATHON_GOAL;
                vars.goal_id = this.cru_modal.goal.id;
            } else {
                vars.hackathon_id = this.hackathon_id;
            }

            this.$apollo
                .mutate({
                    mutation: mutation,
                    variables: vars,
                })
                .then(() => {
                    this.$apollo.queries.get_hackathon_goals.refetch();

                    this.cru_modal.loading = false;

                    this.cru_modal.success = true;
                    setTimeout(() => {
                        this.cru_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("cru_modal");
                    }, 800);

                    this.$notify({
                        message: `Successfully saved goal`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.cru_modal.loading = false;
                    this.$notify({
                        message: `Could not save goal`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                });
        },

        // d_modal
        d_modal_show(goal) {
            if (this.is_hackathon_manager) {
                if (goal) {
                    this.d_modal.goal = goal;
                } else {
                    this.d_modal.goal = {
                        id: null,
                        description: "",
                        priority: null,
                    };
                }
                this.$bvModal.show("d_modal");
            } else {
                this.d_modal.goal = {
                    id: null,
                    description: "",
                    priority: null,
                };
            }
        },
        delete_goal() {
            this.d_modal.loading = true;
            this.$apollo
                .mutate({
                    mutation: DELETE_HACKATHON_GOAL,
                    variables: {
                        goal_id: this.d_modal.goal.id,
                    },
                })
                .then(() => {
                    this.$apollo.queries.get_hackathon_goals.refetch();

                    this.d_modal.loading = false;

                    this.d_modal.success = true;
                    setTimeout(() => {
                        this.d_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("d_modal");
                    }, 800);

                    this.$notify({
                        message: `Successfully deleted goal`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.d_modal.loading = false;
                    this.$notify({
                        message: `Could not delete goal`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                });
        },

        //#endregion

        //#region Scrollbar
        // Scroll bar
        init_scrollbar() {
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                init_scrollbar("goal-item-list");
            }
        },

        get_max_height_goal_item_container() {
            
            document.getElementById(
                "goal-item-container"
            ).style.height = `${100}px`;
            let height = 0;
            setTimeout(() => {
                document
                    .querySelectorAll(".goal-list-item")
                    .forEach((el) => {
                        height += el.offsetHeight;
                    });

                if (height > this.max_height) {
                    height = this.max_height;
                }
                document.getElementById(
                    "goal-item-container"
                ).style.height = `${height}px`;
            }, 100);
        },
        //#endregion

        get_priority_suffix(priority) {
            if (priority == null) {
                return "";
            }
            if (priority > 10 && priority < 20) {
                return "th";
            } else if (String(priority).endsWith("1")) {
                return "st";
            } else if (String(priority).endsWith("2")) {
                return "nd";
            } else if (String(priority).endsWith("3")) {
                return "rd";
            } else {
                return "th";
            }
        },
        sort_by_key(array, key) {
            return array.sort(function (a, b) {
                var x = a[key];
                var y = b[key];
                return x < y ? -1 : x > y ? 1 : 0;
            });
        },

        manage_queries() {
            if (this.hackathon_id) {
                if (this.un_auth && this.hackathon_uid) {
                    graph_utils.enable_query(
                        this.$apollo.queries.get_hackathon_goals_un_auth
                    );
                    if (
                        !this.$apollo.queries.get_hackathon_goals_un_auth.skip
                    ) {
                        this.$apollo.queries.get_hackathon_goals_un_auth.refetch();
                    }
                } else {
                    graph_utils.enable_query(
                        this.$apollo.queries.get_hackathon_goals
                    );
                    if (!this.$apollo.queries.get_hackathon_goals.skip) {
                        this.$apollo.queries.get_hackathon_goals.refetch();
                    }
                }
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_goals
                );
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_goals_un_auth
                );
            }
        },


    },
    watch: {
        hackathon_id() {
            this.manage_queries(); 
        },
        refresh() {
            this.manage_queries(); 
        },
    },
    mounted() {
        this.manage_queries(); 
        this.init_scrollbar();
    },
};
</script>
<style>
#goal-item-container {
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
}
</style>
