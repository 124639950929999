<template>
    <div>
        <!-- CRU Modal -->
        <b-modal
            id="prizes_cru_modal"
            :title="`${
                prizes_cru_modal.prize.id
                    ? `Update ${
                          prizes_cru_modal.prize.position
                      }${get_position_suffix(
                          prizes_cru_modal.prize.position
                      )} Place Prize`
                    : 'Create Prize'
            }`"
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div>
                <b-row>
                    <b-col>
                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form
                                role="form"
                                @submit.prevent="handleSubmit(submit_form)"
                            >
                                <input
                                    type="submit"
                                    ref="prizes_cru_modal_form_submit_button"
                                    hidden
                                />
                                <b-row>
                                    <b-col sm="3" class="pr-1">
                                        <el-tooltip
                                            content="Placement in the competition, e.g. 1st, 2nd, 3rd"
                                            placement="top"
                                        >
                                            <base-input
                                                label="Place"
                                                :rules="{
                                                    required: true,
                                                    numeric: true,
                                                    min_value: 1,
                                                }"
                                                :pill="true"
                                                type="number"
                                                name="Place"
                                                placeholder="0"
                                                :disabled="$apollo.loading"
                                                v-model="
                                                    prizes_cru_modal.prize
                                                        .position
                                                "
                                            >
                                            </base-input>
                                        </el-tooltip>
                                    </b-col>
                                    <b-col class="pt-4 mt-3 pl-0">
                                        {{
                                            get_position_suffix(
                                                prizes_cru_modal.prize.position
                                            )
                                        }}
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <html-editor
                                            :pill="true"
                                            label="Prize detail description"
                                            class="mb-3"
                                            name="Detailed Description"
                                            placeholder="A detail description"
                                            v-model="
                                                prizes_cru_modal.prize
                                                    .description
                                            "
                                            :disabled="$apollo.loading"
                                        >
                                        </html-editor>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-right">
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('prizes_cru_modal')"
                            :disabled="prizes_cru_modal.loading"
                            >Close
                        </base-button>
                        <base-button
                            type="success"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="prizes_cru_modal_save()"
                            :disabled="prizes_cru_modal.loading"
                            :loading="prizes_cru_modal.loading"
                            :success="prizes_cru_modal.success"
                            >Save
                        </base-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>

        <!-- Delete Modal -->
        <b-modal
            id="prizes_d_modal"
            title="Confirm"
            size="sm"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div class="text-center">
                <h1>Are you sure?</h1>
                <p>
                    You are about to delete the prize for place:
                    {{ prizes_d_modal.prize.position }}
                </p>
                <base-button
                    type="danger"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="delete_prize()"
                    :loading="prizes_d_modal.loading"
                    :disabled="prizes_d_modal.loading"
                    :success="prizes_d_modal.success"
                >
                    Delete
                </base-button>
                <base-button
                    type="primary"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="$bvModal.hide('prizes_d_modal')"
                    :disabled="prizes_d_modal.loading"
                    >Cancel
                </base-button>
            </div>
        </b-modal>

        <!-- Prizes -->
        <b-row>
            <b-col cols="6">
                <h1
                    v-if="has_heading"
                    :class="heading_classes"
                    class="pb-0 mb-0"
                >
                    Prizes
                </h1>
            </b-col>
            <b-col cols="6" class="text-right mt-2">
                <el-tooltip
                    content="Create Prize"
                    placement="top"
                    v-if="is_hackathon_manager || is_hackathon_creator"
                >
                    <base-button
                        type="primary"
                        round
                        icon
                        size="sm"
                        @click="prizes_cru_modal_show()"
                    >
                        <span class="btn-inner"
                            ><i class="fa fa-plus"></i
                        ></span>
                    </base-button>
                </el-tooltip>
            </b-col>
        </b-row>

        <div
            v-show="prizes.length > 0"
            class="prize-item-list"
            id="prize-item-container"
        >
            <b-list-group flush data-toggle="checklist">
                <b-list-group-item
                    class="checklist-entry flex-column align-items-start py-2 px-2 prize-list-item"
                    v-for="prize in prizes"
                    :key="prize.id"
                >
                    <b-row>
                        <b-col sm="auto">
                            <i class="fas fa-trophy text-primary"></i> &nbsp;
                            {{ prize.position
                            }}{{ get_position_suffix(prize.position) }}
                        </b-col>
                        <b-col>
                            <small v-html="prize.description"></small>
                        </b-col>
                        <b-col
                            class="text-right"
                            sm="auto"
                            v-if="is_hackathon_manager || is_hackathon_creator"
                        >
                            <el-tooltip content="Edit" placement="top">
                                <a
                                    href="#!"
                                    @click.prevent="
                                        prizes_cru_modal_show(prize)
                                    "
                                    class="table-action"
                                    data-toggle="tooltip"
                                    data-original-title="Update Prize"
                                >
                                    <i class="fas fa-edit"></i>
                                </a>
                            </el-tooltip>
                            <el-tooltip content="Delete" placement="top">
                                <a
                                    href="#!"
                                    @click.prevent="prizes_d_modal_show(prize)"
                                    class="table-action"
                                    data-toggle="tooltip"
                                    data-original-title="Update Prize"
                                >
                                    <i class="fas fa-trash"></i>
                                </a> </el-tooltip
                        ></b-col>
                    </b-row>
                </b-list-group-item>
            </b-list-group>
        </div>
        <b-row
            class="text-center"
            v-if="!$apollo.loading && prizes.length <= 0"
        >
            <b-col>
                <h4 class="text-muted">No Prizes Yet</h4>
            </b-col>
        </b-row>
        <b-row v-if="$apollo.loading" class="text-center">
            <b-col>
                <h1>
                    <i class="fas fa-spinner fa-spin ml-1"></i>
                </h1>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import HtmlEditor from "@/components/Inputs/HtmlEditor";
// Queries
import { GET_HACKATHON_PRIZES } from "@/graphql/queries";
import { GET_HACKATHON_PRIZES_UN_AUTH } from "@/graphql/queriesUnAuth";

// Mutations
import {
    CREATE_HACKATHON_PRIZE,
    UPDATE_HACKATHON_PRIZE,
    DELETE_HACKATHON_PRIZE,
} from "@/graphql/mutations";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
function has_element(className) {
    return document.getElementsByClassName(className).length > 0;
}

function init_scrollbar(className) {
    if (has_element(className)) {
        new PerfectScrollbar(`.${className}`, { suppressScrollX: true });
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            init_scrollbar(className);
        }, 100);
    }
}

export default {
    components: { HtmlEditor },
    props: {
        hackathon_id: {
            type: String,
            description: "The Id of the hackathon being viewed.",
            default: null,
        },
        hackathon_uid: {
            type: String,
            description: "The Id of the hackathon being viewed.",
            default: null,
        },
        has_heading: {
            type: Boolean,
            description: "Controls if the heading is displayed",
            default: true,
        },
        height: {
            type: Number,
            description: "Controls the max height of the list",
            default: 450,
        },
        heading_classes: {
            type: Object,
            description: "Classes of the heading",
            default() {
                return {
                    "display-3": true,
                    "text-primary": true,
                };
            },
        },
        un_auth: {
            type: Boolean,
            description: "Controls if the un_auth api should be used.",
            default: false,
        },
    },
    apollo: {
        get_hackathon_prizes: {
            query: GET_HACKATHON_PRIZES,
            result(res) {
                let flattened = graph_utils.flattened_response(res.data);
                this.prizes = [];

                flattened.allHackathonPrizes.forEach((el) => {
                    this.prizes.push({
                        id: el.id,
                        description: el.description,
                        position: el.position,
                    });
                });

                this.prizes = this.sort_by_key(this.prizes, "position");

                this.is_hackathon_creator =
                    res.data.hackathonHackathon.isCreator;
                this.is_hackathon_manager =
                    res.data.hackathonHackathon.isManager;

                this.get_max_height_prize_item_container();
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_prizes = data;
            },
            skip: true,
        },
        get_hackathon_prizes_un_auth: {
            client: "apolloClientUnAuth",
            query: GET_HACKATHON_PRIZES_UN_AUTH,
            result(res) {
                let flattened = graph_utils.flattened_response(res.data);
                this.prizes = [];
                flattened.allHackathonPrizes.forEach((el) => {
                    this.prizes.push({
                        id: el.id,
                        description: el.description,
                        position: el.position,
                    });
                });

                this.prizes = this.sort_by_key(this.prizes, "position");

                this.get_max_height_prize_item_container();
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                    hackathon_uid: this.hackathon_uid,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_prizes_un_auth = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_prizes: null,
                get_hackathon_prizes_un_auth: null,
            },
            prizes: [],
            is_hackathon_creator: false,
            is_hackathon_manager: false,

            prizes_cru_modal: {
                loading: false,
                success: false,

                prize: {
                    id: null,
                    description: "",
                    position: null,
                },
            },
            prizes_d_modal: {
                loading: false,
                success: false,
                prize: {
                    id: null,
                    position: null,
                    description: null,
                },
            },
        };
    },
    methods: {
        //#region Modals

        // prizes_cru_modal
        prizes_cru_modal_show(prize) {
            if (this.is_hackathon_manager) {
                if (prize) {
                    this.prizes_cru_modal.prize = prize;
                } else {
                    this.prizes_cru_modal.prize = {
                        id: null,
                        description: "",
                        position: null,
                    };
                }
                this.$bvModal.show("prizes_cru_modal");
            } else {
                this.prizes_cru_modal.prize = {
                    id: null,
                    description: "",
                    position: null,
                };
            }
        },
        prizes_cru_modal_save() {
            this.prizes_cru_modal.loading = true;

            let vars = {
                description: this.prizes_cru_modal.prize.description,
                position: Number(this.prizes_cru_modal.prize.position),
            };

            let mutation = CREATE_HACKATHON_PRIZE;
            if (this.prizes_cru_modal.prize.id) {
                mutation = UPDATE_HACKATHON_PRIZE;
                vars.prize_id = this.prizes_cru_modal.prize.id;
            } else {
                vars.hackathon_id = this.hackathon_id;
            }

            this.$apollo
                .mutate({
                    mutation: mutation,
                    variables: vars,
                })
                .then(() => {
                    this.$apollo.queries.get_hackathon_prizes.refetch();

                    this.prizes_cru_modal.loading = false;

                    this.prizes_cru_modal.success = true;
                    setTimeout(() => {
                        this.prizes_cru_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("prizes_cru_modal");
                    }, 800);

                    this.$notify({
                        message: `Successfully saved prize`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.prizes_cru_modal.loading = false;
                    this.$notify({
                        message: `Could not save prize`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                });
        },

        // prizes_d_modal
        prizes_d_modal_show(prize) {
            if (this.is_hackathon_manager) {
                if (prize) {
                    this.prizes_d_modal.prize = prize;
                } else {
                    this.prizes_d_modal.prize = {
                        id: null,
                        description: "",
                        position: null,
                    };
                }
                this.$bvModal.show("prizes_d_modal");
            } else {
                this.prizes_d_modal.prize = {
                    id: null,
                    description: "",
                    position: null,
                };
            }
        },
        delete_prize() {
            this.prizes_d_modal.loading = true;
            this.$apollo
                .mutate({
                    mutation: DELETE_HACKATHON_PRIZE,
                    variables: {
                        prize_id: this.prizes_d_modal.prize.id,
                    },
                })
                .then(() => {
                    this.$apollo.queries.get_hackathon_prizes.refetch();

                    this.prizes_d_modal.loading = false;

                    this.prizes_d_modal.success = true;
                    setTimeout(() => {
                        this.prizes_d_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("prizes_d_modal");
                    }, 800);

                    this.$notify({
                        message: `Successfully deleted prize`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.prizes_d_modal.loading = false;
                    this.$notify({
                        message: `Could not delete prize`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                });
        },

        //#endregion

        //#region Scrollbar
        // Scroll bar
        init_scrollbar() {
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                init_scrollbar("prize-item-list");
            }
        },

        get_max_height_prize_item_container() {
            document.getElementById(
                "prize-item-container"
                ).style.height = `${100}px`;
                let height = 0;
            setTimeout(() => {
                document
                    .querySelectorAll(".prize-list-item")
                    .forEach((el) => {
                        height += el.offsetHeight;
                    });

                if (height > this.max_height) {
                    height = this.max_height;
                }
                document.getElementById(
                    "prize-item-container"
                ).style.height = `${height}px`;
            }, 100);
        },
        //#endregion

        get_position_suffix(position) {
            if (position == null) {
                return "";
            }
            if (position > 10 && position < 20) {
                return "th";
            } else if (String(position).endsWith("1")) {
                return "st";
            } else if (String(position).endsWith("2")) {
                return "nd";
            } else if (String(position).endsWith("3")) {
                return "rd";
            } else {
                return "th";
            }
        },
        sort_by_key(array, key) {
            return array.sort(function (a, b) {
                var x = a[key];
                var y = b[key];
                return x < y ? -1 : x > y ? 1 : 0;
            });
        },

        manage_queries() {
            if (this.hackathon_id) {
                if (this.un_auth && this.hackathon_uid) {
                    graph_utils.enable_query(
                        this.$apollo.queries.get_hackathon_prizes_un_auth
                    );
                    if (
                        !this.$apollo.queries.get_hackathon_prizes_un_auth.skip
                    ) {
                        this.$apollo.queries.get_hackathon_prizes_un_auth.refetch();
                    }
                } else if (!this.un_auth){
                    graph_utils.enable_query(
                        this.$apollo.queries.get_hackathon_prizes
                    );
                    if (!this.$apollo.queries.get_hackathon_prizes.skip) {
                        this.$apollo.queries.get_hackathon_prizes.refetch();
                    }
                }
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_prizes
                );
            }
        },
    },
    watch: {
        hackathon_id() {
            this.manage_queries();
        },
    },
    mounted() {
        this.manage_queries();
        this.init_scrollbar();
    },
};
</script>
<style>
#prize-item-container {
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
}
</style>
